import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const DatePicker = ({ name, label }) => {
    const {
        formState: { errors },
        control,
    } = useFormContext();

    const error = errors[name];

    const helperText = error ? error.message || error.type : '';

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (
                        <DesktopDatePicker
                            onChange={(date) => field.onChange(date)}
                            value={field.value ? new Date(field.value) : null}
                            label={label}
                            slotProps={{
                                textField: {
                                    helperText: helperText,
                                    error: !!error,
                                },
                            }}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );
};

export default DatePicker;
