import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Box, ButtonGroup, Slide, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@magnetic/button';
import { PlusCircle } from '@magnetic/icons';

import { boxStyles, defaultValues, schema } from './config';
import {
    createRole,
    resetRoleStatus,
} from '../../../../../../slices/userRoles/userRolesSlice';
import { SubmitButton, TextInput } from '../../../../../../components/form';
import { FETCH_STATUS } from '../../../../../../common/constants';

const AddNewRoleForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isAddRoleMode, setIsAddRoleMode] = useState(false);

    const { createRoleResult, createRoleStatus } = useSelector(
        (state) => state.userRoles,
    );

    const methods = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    const {
        handleSubmit,
        clearErrors,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = (values) => {
        dispatch(createRole({ payload: values }));
    };

    useEffect(() => {
        if (createRoleStatus === FETCH_STATUS.SUCCESS) {
            dispatch(resetRoleStatus());
            history.push(`/dashboard/user-roles/${createRoleResult.id}`);
        }
    }, [createRoleResult, createRoleStatus]);

    const onCancel = () => {
        setIsAddRoleMode(false);
        clearErrors();
    };

    return (
        <Box sx={{ ...boxStyles, mb: '0.5rem' }}>
            <Box>
                {!isAddRoleMode ? (
                    <Button
                        onClick={() => setIsAddRoleMode(true)}
                        icon={<PlusCircle weight="fill" size={20} />}
                        data-testid="addRoleButton"
                    >
                        Add Role
                    </Button>
                ) : (
                    <Slide direction="left" in mountOnEnter unmountOnExit>
                        <Box>
                            <FormProvider {...methods}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Stack direction="row" spacing={3}>
                                        <TextInput
                                            label="Role name"
                                            name="name"
                                            data-testid="addNewRoleInput"
                                        />

                                        <ButtonGroup
                                            sx={{
                                                gap: 3,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Button
                                                onClick={onCancel}
                                                kind="tertiary"
                                                data-testid="cancelAddNewRole"
                                            >
                                                Cancel
                                            </Button>

                                            <SubmitButton
                                                isLoading={isSubmitting}
                                                data-testid="submitAddNewRole"
                                            >
                                                Save
                                            </SubmitButton>
                                        </ButtonGroup>
                                    </Stack>
                                </form>
                            </FormProvider>
                        </Box>
                    </Slide>
                )}
            </Box>
        </Box>
    );
};

export default AddNewRoleForm;
