/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../../../common/constants';
import apiV1 from '../../../../services/axiosConfig/apiV1';

const initialState = {
    migrationStatus: FETCH_STATUS.INIT,
    migrationInfo: {},
};

export const migrateParentOrg = createAsyncThunk(
    'parentOrgMigration/migrateParentOrg',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(
                'migrate',
                'post',
                payload,
            );

            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

const parentOrgMigrationSlice = createSlice({
    name: 'parentOrgMigrationSlice',
    initialState,
    reducers: {
        resetMigrationStatus(state) {
            return {
                ...state,
                migrationStatus: FETCH_STATUS.INIT,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(migrateParentOrg.pending, (state) => {
                state.migrationStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(migrateParentOrg.fulfilled, (state, action) => {
                state.migrationStatus = FETCH_STATUS.SUCCESS;
                state.migrationInfo = action?.payload;
            })
            .addCase(migrateParentOrg.rejected, (state) => {
                state.migrationStatus = FETCH_STATUS.ERROR;
            });
    },
});

export const { resetMigrationStatus } = parentOrgMigrationSlice.actions;

export default parentOrgMigrationSlice.reducer;
