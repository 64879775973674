/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../../../common/constants';
import apiV1 from '../../../../services/axiosConfig/apiV1';

const initialState = {
    createOrgStatus: FETCH_STATUS.INIT,
    createOrgResult: {},
};

export const createOrg = createAsyncThunk(
    'organization/createOrg',
    async (createOrgData, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(
                '/bfg/organizations/console/create',
                'post',
                createOrgData,
            );
            return response;
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

const createOrgComponentSlice = createSlice({
    name: 'createOrgSlice',
    initialState,
    reducers: {
        clearCreateOrgStatus(state) {
            return {
                ...state,
                createOrgStatus: FETCH_STATUS.INIT,
            };
        },
        clearCreateOrgResult(state) {
            return {
                ...state,
                createOrgResult: {},
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createOrg.pending, (state) => {
                state.createOrgStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(createOrg.fulfilled, (state, action) => {
                state.createOrgStatus = FETCH_STATUS.SUCCESS;
                state.createOrgResult = action?.payload;
            })
            .addCase(createOrg.rejected, (state, action) => {
                state.createOrgStatus = FETCH_STATUS.ERROR;
                state.createOrgResult = action?.payload;
            });
    },
});

export const { clearCreateOrgResult, clearCreateOrgStatus } =
    createOrgComponentSlice.actions;

export default createOrgComponentSlice.reducer;
