/* eslint-disable no-param-reassign */
import {
    bindActionCreators,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { updateAlertParams } from '../alert/alertSlice';
import { FETCH_STATUS } from '../../common/constants';
import apiV1 from '../../services/axiosConfig/apiV1';
import { errorHandler } from '../../common/utils';

const BASE_PREFIX = '/bfg/features';

const initialState = {
    getFeaturesStatus: FETCH_STATUS.INIT,
    getFeaturesResult: {},
    // fet features set
    getFeatureSetsStatus: FETCH_STATUS.INIT,
    getFeatureSetsResult: [],
    // update feature set
    updateFeatureSetStatus: FETCH_STATUS.INIT,
    // delete feature set
    deleteFeatureSetStatus: FETCH_STATUS.INIT,
    // create feature
    createFeatureStatus: FETCH_STATUS.INIT,
    createdFeatureResult: {},
    // update feature
    updateFeatureStatus: FETCH_STATUS.INIT,
    updatedFeatureResult: {},
    // delete feature
    deleteFeatureStatus: FETCH_STATUS.INIT,
    deleteFeatureResult: {},
};

export const createFeature = createAsyncThunk(
    'createFeature',
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                BASE_PREFIX,
                'post',
                payload,
            );
            const message = 'Feature created successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const updateFeature = createAsyncThunk(
    'updateFeature',
    async ({ payload, featureId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${featureId}`,
                'put',
                payload,
            );
            const message = 'Feature updated successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const deleteFeature = createAsyncThunk(
    'deleteFeature',
    async (featureId, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${featureId}`,
                'delete',
            );
            const message = 'Feature deleted successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const getAllFeatures = createAsyncThunk(
    'getFeatures',
    async (_, { rejectWithValue, dispatch }) => {
        const url = `${BASE_PREFIX}`;
        try {
            const response = await apiV1.sendRequest(url, 'get');
            return response.data;
        } catch (err) {
            const message =
                err?.error?.text || err?.error || 'Validation error';
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

export const getAllFeatureSets = createAsyncThunk(
    'getAllFeatureSet',
    async (_, { rejectWithValue, dispatch }) => {
        const url = `bfg/feature-set`;
        try {
            const response = await apiV1.sendRequest(url, 'get');
            return response.data.data;
        } catch (err) {
            const message =
                err?.error?.text || err?.error || 'Validation error';
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

export const createFeatureSet = createAsyncThunk(
    'createFeatureSet',
    async (payload, { rejectWithValue, dispatch }) => {
        const url = `bfg/feature-set`;
        try {
            const response = await apiV1.sendRequest(url, 'post', payload);
            const message = 'Feature set created successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response.data.data;
        } catch (err) {
            const message = err?.error
                ? err.error
                : 'Something went wrong, please, try again!';
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

export const deleteFeatureSet = createAsyncThunk(
    'deleteFeatureSet',
    async ({ id }, { rejectWithValue, dispatch }) => {
        const url = `bfg/feature-set/${id}`;
        try {
            const response = await apiV1.sendRequest(url, 'delete');
            const message = 'Feature set deleted successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response.data.data;
        } catch (err) {
            const message = err?.error
                ? err.error
                : 'Something went wrong, please, try again!';
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

export const updateFeatureSet = createAsyncThunk(
    'updateFeatureSet',
    async ({ id, payload }, { rejectWithValue, dispatch }) => {
        const url = `bfg/feature-set/${id}`;
        try {
            const response = await apiV1.sendRequest(url, 'put', payload);
            const message = 'Feature set updated successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response.data.data;
        } catch (err) {
            const message = err?.error
                ? err.error
                : 'Something went wrong, please, try again!';
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

const featuresSlice = createSlice({
    name: 'FeaturesSlice',
    initialState,
    reducers: {
        setSearch: (state, action) => {
            state.searchText = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // createFeature
            .addCase(createFeature.pending, (state) => {
                state.createFeatureStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(createFeature.fulfilled, (state, action) => {
                state.createFeatureStatus = FETCH_STATUS.SUCCESS;
                state.createdFeatureResult = action.payload;
            })
            .addCase(createFeature.rejected, (state) => {
                state.createFeatureStatus = FETCH_STATUS.ERROR;
            })

            // updateFeature
            .addCase(updateFeature.pending, (state) => {
                state.updatedFeatureStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(updateFeature.fulfilled, (state, action) => {
                state.updatedFeatureStatus = FETCH_STATUS.SUCCESS;
                state.updatedFeatureResult = action.payload;
                const updatedItem = action.payload.data.data;
                const updatedFeatureId = updatedItem.id;
                const record = state.getFeaturesResult.data.find(
                    (feature) => feature.id === updatedFeatureId,
                );

                Object.assign(record, updatedItem);
            })
            .addCase(updateFeature.rejected, (state) => {
                state.updatedFeatureStatus = FETCH_STATUS.ERROR;
            })

            // deleteFeature
            .addCase(deleteFeature.pending, (state) => {
                state.deleteFeatureStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(deleteFeature.fulfilled, (state) => {
                state.deleteFeatureStatus = FETCH_STATUS.SUCCESS;
            })
            .addCase(deleteFeature.rejected, (state) => {
                state.deleteFeatureStatus = FETCH_STATUS.ERROR;
            })

            // getAllFeatures
            .addCase(getAllFeatures.pending, (state) => {
                state.getFeaturesStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(getAllFeatures.fulfilled, (state, action) => {
                state.getFeaturesStatus = FETCH_STATUS.SUCCESS;
                state.getFeaturesResult = action?.payload;
            })
            .addCase(getAllFeatures.rejected, (state) => {
                state.getFeaturesStatus = FETCH_STATUS.ERROR;
            })

            // getAllFeatureSet
            .addCase(getAllFeatureSets.pending, (state) => {
                state.getFeatureSetsStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(getAllFeatureSets.fulfilled, (state, action) => {
                state.getFeatureSetsStatus = FETCH_STATUS.SUCCESS;
                state.getFeatureSetsResult = action.payload;
            })
            .addCase(getAllFeatureSets.rejected, (state) => {
                state.getFeatureSetsStatus = FETCH_STATUS.ERROR;
            })

            // create feature set
            .addCase(createFeatureSet.fulfilled, (state, action) => {
                state.getFeatureSetsResult = [
                    action.payload,
                    ...state.getFeatureSetsResult,
                ];
            })
            .addCase(deleteFeatureSet.fulfilled, (state, action) => {
                state.getFeatureSetsResult = state.getFeatureSetsResult.filter(
                    (item) => item.id !== action.payload.item.id,
                );
                state.deleteFeatureSetStatus = FETCH_STATUS.SUCCESS;
            })
            .addCase(deleteFeatureSet.rejected, (state) => {
                state.deleteFeatureSetStatus = FETCH_STATUS.ERROR;
            })

            // update feature set
            .addCase(updateFeatureSet.fulfilled, (state, action) => {
                const updatedItem = action.payload.item;
                const updatedItemId = updatedItem.id;
                const record = state.getFeatureSetsResult.find(
                    (set) => set.id === updatedItemId,
                );

                Object.assign(record, updatedItem);
                state.updateFeatureSetStatus = FETCH_STATUS.SUCCESS;
            })
            .addCase(updateFeatureSet.rejected, (state) => {
                state.updateFeatureSetStatus = FETCH_STATUS.ERROR;
            });
    },
});

export const { setSearch } = featuresSlice.actions;

export const featuresSliceActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(
        {
            updateFeature,
            deleteFeature,
            getAllFeatures,
            getAllFeatureSets,
            createFeatureSet,
        },
        dispatch,
    );
};

export default featuresSlice.reducer;
