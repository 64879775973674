// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noPageFound-moduleQtpIEe5IqaUkOntMlO1h{align-items:center;display:flex;flex-direction:column;height:100vh;justify-content:center;text-align:center}.noPageFound-moduleQtpIEe5IqaUkOntMlO1h p{font-size:30px;font-weight:bold}.noPageFound-moduleQtpIEe5IqaUkOntMlO1h p:not(:last-child){margin-bottom:15px}.noPageFound-moduleWTIZwZ3RerDi9H08SDiy{margin-top:40px}`, "",{"version":3,"sources":["webpack://./src/components/noPageFound/noPageFound.module.scss"],"names":[],"mappings":"AAEA,wCACI,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA,CACA,iBAAA,CAEA,0CACI,cAAA,CACA,gBAAA,CAGJ,2DACI,kBAAA,CAIR,wCACI,eAAA","sourceRoot":""}]);
// Exports
export var green7 = `#409618`;
export var red7 = `#d4503b`;
export var red8 = `#c24632`;
export var wrapper = `noPageFound-moduleQtpIEe5IqaUkOntMlO1h`;
export var btn = `noPageFound-moduleWTIZwZ3RerDi9H08SDiy`;
export default ___CSS_LOADER_EXPORT___;
