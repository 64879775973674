import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from '../App';
import store from '../store';

require('@ciscodesignsystems/cds-react-date-time-picker/index.css');
require('@ciscodesignsystems/cds-magnetic-theme-web/css/dist/token-theme-classic-light-variables.css');

import '../styles/index.scss';
import '@magnetic/core/dist/esm/style.css';
import '@magnetic/core/dist/cjs/style.css';

const container = document.getElementById('appRoot');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <BrowserRouter basename="/">
            <App />
        </BrowserRouter>
    </Provider>,
);
