import React from 'react';
import { Spinner } from '@magnetic/spinner';
import { Button } from '@magnetic/button';
import { Modal } from '@magnetic/modal';
import { Flex } from '@magnetic/flex';

import * as g from '../../styles/globalStyles.module.scss';

const ModalContainer = ({
    isOpen,
    handleClose,
    isFetching,
    submitBtnAction,
    submitBtnName,
    submitBtnVariant,
    cancelBtnAction,
    cancelBtnName,
    isSubmitDisabled,
    children,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <Flex
                direction="column"
                gap="md"
                margin={{ bottom: 12 }}
                className={g.labelColor}
            >
                {children}
            </Flex>

            {submitBtnName && cancelBtnName && (
                <Flex justify="flex-end" margin={{ top: 12 }}>
                    <Button.Group style={{ paddingTop: 24 }}>
                        <Button
                            kind="secondary"
                            onClick={cancelBtnAction}
                            data-testid="cancelModalAction"
                        >
                            {cancelBtnName}
                        </Button>

                        <Button
                            destructive={submitBtnVariant}
                            data-testid="submitModalAction"
                            onClick={submitBtnAction}
                            disabled={isSubmitDisabled}
                        >
                            {submitBtnName}
                        </Button>
                    </Button.Group>
                </Flex>
            )}

            {isFetching && (
                <div className={g.contentPreloaderWithBlur}>
                    <Spinner />
                </div>
            )}
        </Modal>
    );
};

export default ModalContainer;
