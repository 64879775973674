// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entryWrapper-modulezn01TwOrCgLdWjyLVkAw{background:url("https://login.umbrella.com/img/bg/umbrella-bg.jpg") center top no-repeat;background-size:cover;height:100%;overflow:auto;position:fixed;width:100%;z-index:-1}.entryWrapper-modulexVmYgEYN3VPZ37VtVfQo{align-items:center;display:flex;flex-direction:row;justify-content:center;min-height:100%;overflow:auto;padding-bottom:38px;width:100%}.entryWrapper-modulessrhkSNEPvefDmpe7rWh{align-items:center;background-color:#fff;box-shadow:0 2px 2px 0 rgba(7,16,19,.14),0 3px 1px -2px rgba(7,16,19,.2),0 1px 5px 0 rgba(7,16,19,.12);display:flex;flex-direction:column;padding:30px 80px;position:relative;width:480px}.entryWrapper-modulessrhkSNEPvefDmpe7rWh>h2{color:#39393b;font-size:30px;font-weight:200;line-height:39px;margin:10px 0 20px;text-align:center}.entryWrapper-modulessrhkSNEPvefDmpe7rWh>p{color:#39393b;font-size:14px;font-weight:400;line-height:20px;margin-bottom:14px}`, "",{"version":3,"sources":["webpack://./src/components/entryWrapper/entryWrapper.module.scss","webpack://./src/styles/variables/_colors.scss"],"names":[],"mappings":"AAEA,yCACI,wFAAA,CAEA,qBAAA,CACA,WAAA,CACA,aAAA,CACA,cAAA,CACA,UAAA,CACA,UAAA,CAGJ,yCACI,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,aAAA,CACA,mBAAA,CACA,UAAA,CAGJ,yCACI,kBAAA,CACA,qBC1BI,CD2BJ,sGACI,CAGJ,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,iBAAA,CACA,WAAA,CAEA,4CACI,aCpBE,CDqBF,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,2CACI,aC7BE,CD8BF,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
export var green7 = `#409618`;
export var red7 = `#d4503b`;
export var red8 = `#c24632`;
export var entryWrapper = `entryWrapper-modulezn01TwOrCgLdWjyLVkAw`;
export var formContainer = `entryWrapper-modulexVmYgEYN3VPZ37VtVfQo`;
export var form = `entryWrapper-modulessrhkSNEPvefDmpe7rWh`;
export default ___CSS_LOADER_EXPORT___;
