/* eslint-disable no-param-reassign */
import {
    bindActionCreators,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { updateAlertParams } from '../alert/alertSlice';
import { FETCH_STATUS } from '../../common/constants';
import apiV1 from '../../services/axiosConfig/apiV1';

const BASE_PREFIX = '/bfg/organizations';
const ERROR_MESSAGE = 'Something wrong on api side';

const initialState = {
    getOrganizationFeaturesStatus: FETCH_STATUS.INIT,
    getOrganizationFeaturesResult: {},

    // add feature to Organization
    addFeatureToOrganizationStatus: FETCH_STATUS.INIT,
    addFeatureToOrganizationResult: {},

    // add featureSet to Organization
    addFeatureSetToOrganizationStatus: FETCH_STATUS.INIT,
    addFeatureSetToOrganizationResult: {},
    // update organizationFeature
    updatedOrganizationFeatureStatus: FETCH_STATUS.INIT,
    updatedOrganizationFeatureResult: {},
    // delete organizationFeature
    deleteOrganizationFeatureStatus: FETCH_STATUS.INIT,
    deleteOrganizationFeatureResult: {},
};

export const addFeatureToOrganization = createAsyncThunk(
    'addFeatureToOrganization',
    async ({ orgId, payload }, { rejectWithValue, dispatch }) => {
        const url = `${BASE_PREFIX}/${orgId}/features`;
        try {
            const response = await apiV1.sendRequest(url, 'post', payload);
            const message = 'Feature added successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response;
        } catch (err) {
            const message = err?.error?.text || err?.error || ERROR_MESSAGE;
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

export const addFeatureSetToOrganization = createAsyncThunk(
    'addFeatureSetToOrganization',
    async ({ orgId, payload }, { rejectWithValue, dispatch }) => {
        const url = `${BASE_PREFIX}/${orgId}/features/featureSet`;
        try {
            const response = await apiV1.sendRequest(url, 'post', payload);
            const message = 'Feature set added successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response;
        } catch (err) {
            const message = err?.error?.text || err?.error || ERROR_MESSAGE;
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

export const updateOrganizationFeature = createAsyncThunk(
    'updateOrganizationFeature',
    async ({ orgId, payload }, { rejectWithValue, dispatch }) => {
        try {
            const url = `${BASE_PREFIX}/${orgId}/features`;
            const response = await apiV1.sendRequest(url, 'put', payload);
            const message = 'OrganizationFeature updated successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response;
        } catch (err) {
            const message = err?.error?.text || err?.error || ERROR_MESSAGE;
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

export const deleteOrganizationFeature = createAsyncThunk(
    'deleteOrganizationFeature',
    async ({ orgId, featureId }, { rejectWithValue, dispatch }) => {
        try {
            const url = `${BASE_PREFIX}/${orgId}/features/${featureId}`;
            const response = await apiV1.sendRequest(url, 'delete');
            const message = 'OrganizationFeature deleted successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response;
        } catch (err) {
            const message = err?.error?.text || err?.error || ERROR_MESSAGE;
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

export const getAllOrganizationFeatures = createAsyncThunk(
    'getOrganizationFeatures',
    async (orgId, { rejectWithValue, dispatch }) => {
        const url = `${BASE_PREFIX}/${orgId}/features`;
        try {
            const response = await apiV1.sendRequest(url, 'get');
            return response.data;
        } catch (err) {
            const message = err?.error?.text || err?.error || ERROR_MESSAGE;
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

const organizationFeaturesSlice = createSlice({
    name: 'OrganizationFeaturesSlice',
    initialState,
    reducers: {
        setSearch: (state, action) => {
            state.searchText = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // addFeatureToOrganization
            .addCase(addFeatureToOrganization.pending, (state) => {
                state.addFeatureToOrganizationStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(addFeatureToOrganization.fulfilled, (state, action) => {
                state.addFeatureToOrganizationStatus = FETCH_STATUS.SUCCESS;
                state.addFeatureToOrganizationResult = action.payload;
            })
            .addCase(addFeatureToOrganization.rejected, (state) => {
                state.addFeatureToOrganizationStatus = FETCH_STATUS.ERROR;
            })

            // addFeatureSetToOrganization
            .addCase(addFeatureSetToOrganization.pending, (state) => {
                state.addFeatureSetToOrganizationStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(addFeatureSetToOrganization.fulfilled, (state, action) => {
                state.addFeatureSetToOrganizationStatus = FETCH_STATUS.SUCCESS;
                state.addFeatureSetToOrganizationResult = action.payload;
            })
            .addCase(addFeatureSetToOrganization.rejected, (state) => {
                state.addFeatureSetToOrganizationStatus = FETCH_STATUS.ERROR;
            })

            // addFeatureToOrganization
            .addCase(updateOrganizationFeature.pending, (state) => {
                state.updatedOrganizationFeatureStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(updateOrganizationFeature.fulfilled, (state, action) => {
                state.updatedOrganizationFeatureStatus = FETCH_STATUS.SUCCESS;
                state.updatedOrganizationFeatureResult = action.payload;
                const updatedItem = action.payload.data.data;
                const updatedOrganizationFeatureId = updatedItem.featureId;
                const record = state.getOrganizationFeaturesResult.data.find(
                    (organizationFeature) =>
                        organizationFeature.featureId ===
                        updatedOrganizationFeatureId,
                );

                Object.assign(record, updatedItem);
            })
            .addCase(updateOrganizationFeature.rejected, (state) => {
                state.updatedOrganizationFeatureStatus = FETCH_STATUS.ERROR;
            })

            // deleteOrganizationFeature
            .addCase(deleteOrganizationFeature.pending, (state) => {
                state.deleteOrganizationFeatureStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(deleteOrganizationFeature.fulfilled, (state) => {
                state.deleteOrganizationFeatureStatus = FETCH_STATUS.SUCCESS;
            })
            .addCase(deleteOrganizationFeature.rejected, (state) => {
                state.deleteOrganizationFeatureStatus = FETCH_STATUS.ERROR;
            })

            // getAllOrganizationFeatures
            .addCase(getAllOrganizationFeatures.pending, (state) => {
                state.getOrganizationFeaturesStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(getAllOrganizationFeatures.fulfilled, (state, action) => {
                state.getOrganizationFeaturesStatus = FETCH_STATUS.SUCCESS;
                state.getOrganizationFeaturesResult = action?.payload;
            })
            .addCase(getAllOrganizationFeatures.rejected, (state) => {
                state.getOrganizationFeaturesStatus = FETCH_STATUS.ERROR;
            });
    },
});

const { setSearch } = organizationFeaturesSlice.actions;

export const organizationFeaturesSliceActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(
        {
            addFeatureSetToOrganization,
            updateOrganizationFeature,
            deleteOrganizationFeature,
            getAllOrganizationFeatures,
            addFeatureToOrganization,
            setSearch,
        },
        dispatch,
    );
};

export default organizationFeaturesSlice.reducer;
