/* eslint-disable no-param-reassign */
import {
    bindActionCreators,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { updateAlertParams } from '../alert/alertSlice';
import { FETCH_STATUS } from '../../common/constants';
import apiV1 from '../../services/axiosConfig/apiV1';
import { errorHandler } from '../../common/utils';

const BASE_PREFIX = '/bfg/messages';

const initialState = {
    // message types
    getMessageTypesStatus: FETCH_STATUS.INIT,
    getMessageTypesResult: [],
    // message importance
    getMessageImportanceOptionsStatus: FETCH_STATUS.INIT,
    getMessageImportanceOptionsResult: [],
    // create message
    createMessageStatus: FETCH_STATUS.INIT,
    createdMessageResult: {},
    // update message
    updateMessageStatus: FETCH_STATUS.INIT,
    updatedMessageResult: {},
    // delete message
    deleteMessageStatus: FETCH_STATUS.INIT,
    deleteMessageResult: {},
    // messages Grid
    getMessagesStatus: FETCH_STATUS.INIT,
    getMessagesResult: { data: [], meta: { total: 0 } },
    // messages search
    searchText: '',
};

export const getMessageTypes = createAsyncThunk(
    'getMessageTypes',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/message-types`,
                'get',
            );
            return response.data.data;
        } catch (err) {
            const message = JSON.parse(err?.error).text;
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

export const getMessageImportanceOptions = createAsyncThunk(
    'getMessageImportanceOptions',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/message-importance`,
                'get',
            );
            return response.data.data;
        } catch (err) {
            const message = JSON.parse(err?.error).text;
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

export const createMessage = createAsyncThunk(
    'createMessage',
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                BASE_PREFIX,
                'post',
                payload,
            );
            const message = 'Message created successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));

            return response;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );

            return rejectWithValue(err);
        }
    },
);

export const updateMessage = createAsyncThunk(
    'updateMessage',
    async ({ payload, messageId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${messageId}`,
                'put',
                payload,
            );
            const message = 'Message updated successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response;
        } catch (err) {
            const message = JSON.parse(err?.error).text;
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

export const deleteMessage = createAsyncThunk(
    'deleteMessage',
    async ({ messageId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${messageId}`,
                'delete',
            );
            const message = 'Message deleted successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const getAllMessages = createAsyncThunk(
    'getMessages',
    async ({ limit, page, title }, { rejectWithValue, dispatch }) => {
        const currentPage = page + 1;
        const url = title
            ? `${BASE_PREFIX}?page=${currentPage}&limit=${limit}&where[title]=${encodeURIComponent(
                  title,
              )}&order[startDate]=DESC`
            : `${BASE_PREFIX}?page=${currentPage}&limit=${limit}&order[startDate]=DESC`;
        try {
            const response = await apiV1.sendRequest(url, 'get');
            return response.data?.data;
        } catch (err) {
            const message = JSON.parse(err?.error).text;
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

const messagesSlice = createSlice({
    name: 'messagessSlice',
    initialState,
    reducers: {
        setSearch: (state, action) => {
            state.searchText = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // getMessageTypes
            .addCase(getMessageTypes.pending, (state) => {
                state.getMessageTypesStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(getMessageTypes.fulfilled, (state, action) => {
                state.getMessageTypesStatus = FETCH_STATUS.SUCCESS;
                state.getMessageTypesResult = action.payload;
            })
            .addCase(getMessageTypes.rejected, (state) => {
                state.getMessageTypesStatus = FETCH_STATUS.ERROR;
            })

            // getMessageImportanceOptions
            .addCase(getMessageImportanceOptions.pending, (state) => {
                state.getMessageImportanceOptionsStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(getMessageImportanceOptions.fulfilled, (state, action) => {
                state.getMessageImportanceOptionsStatus = FETCH_STATUS.SUCCESS;
                state.getMessageImportanceOptionsResult = action.payload;
            })
            .addCase(getMessageImportanceOptions.rejected, (state) => {
                state.getMessageImportanceOptionsStatus = FETCH_STATUS.ERROR;
            })

            // createMessage
            .addCase(createMessage.pending, (state) => {
                state.createMessageStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(createMessage.fulfilled, (state, action) => {
                state.createMessageStatus = FETCH_STATUS.SUCCESS;
                state.createdMessageResult = action.payload;
            })
            .addCase(createMessage.rejected, (state) => {
                state.createMessageStatus = FETCH_STATUS.ERROR;
            })

            // updateMessage
            .addCase(updateMessage.pending, (state) => {
                state.updatedMessageStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(updateMessage.fulfilled, (state, action) => {
                state.updatedMessageStatus = FETCH_STATUS.SUCCESS;
                state.updatedMessageResult = action.payload;

                const updatedItem = action.payload.data.data.item;
                const updatedMessageId = updatedItem.id;
                const record = state.getMessagesResult.data.find(
                    (msg) => msg.id === updatedMessageId,
                );

                Object.assign(record, updatedItem);
            })
            .addCase(updateMessage.rejected, (state) => {
                state.updatedMessageStatus = FETCH_STATUS.ERROR;
            })

            // deleteMessage
            .addCase(deleteMessage.pending, (state) => {
                state.deleteMessageStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(deleteMessage.fulfilled, (state) => {
                state.deleteMessageStatus = FETCH_STATUS.SUCCESS;
            })
            .addCase(deleteMessage.rejected, (state) => {
                state.deleteMessageStatus = FETCH_STATUS.ERROR;
            })

            // getAllMessages
            .addCase(getAllMessages.pending, (state) => {
                state.getMessagesStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(getAllMessages.fulfilled, (state, action) => {
                state.getMessagesStatus = FETCH_STATUS.SUCCESS;
                state.getMessagesResult = action.payload;
            })
            .addCase(getAllMessages.rejected, (state) => {
                state.getMessagesStatus = FETCH_STATUS.ERROR;
            });
    },
});

export const { setSearch } = messagesSlice.actions;

export const messageSliceActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(
        {
            getMessageImportanceOptions,
            getMessageTypes,
            // getAllMessages,
        },
        dispatch,
    );
};

export default messagesSlice.reducer;
