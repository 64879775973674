// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginPage-moduleHbEPEzQGOi_7XknPp0jh{margin-bottom:72px;width:100%}.loginPage-moduleUOcpMCblfTM1jz7D5DW0{align-items:center;display:flex}.loginPage-moduleUOcpMCblfTM1jz7D5DW0>input{padding-left:12px}.loginPage-moduleUOcpMCblfTM1jz7D5DW0 .loginPage-moduleUIOI0k1qKlT87a7SWUUl{margin-left:12px;position:absolute}.loginPage-moduleUOcpMCblfTM1jz7D5DW0 .loginPage-moduleUIOI0k1qKlT87a7SWUUl+input{padding-left:40px}.loginPage-moduleUOcpMCblfTM1jz7D5DW0:focus-within input{box-shadow:0 0 5px 1px #049fd9}.loginPage-moduleUOcpMCblfTM1jz7D5DW0:focus-within .loginPage-moduleUIOI0k1qKlT87a7SWUUl svg{fill:#049fd9 !important}.loginPage-moduleW9QncnxDD_yPXBKxogLY{margin-bottom:9px}.loginPage-modulerMvPZDo_o8YPObQZyAgM{background:rgba(0,0,0,0);border-style:none;color:#03688e;cursor:pointer;font-size:14px;font-weight:400;padding:0;text-decoration:none}.loginPage-moduleiQuHD7NuqQ_m7SszQqOv{display:flex;justify-content:space-between;width:70%}.loginPage-moduleRyvfRRiizhFHpRerBdBK{margin-bottom:12px}.loginPage-moduleRhC9RXQd9lSxVOsxnY1k{color:#03688e;cursor:pointer;font-size:14px;font-weight:400;text-decoration:none}.loginPage-moduleBxUgkc_zDlX_p2Ywarzf{margin-top:5px}`, "",{"version":3,"sources":["webpack://./src/pages/loginPage/loginPage.module.scss","webpack://./src/styles/variables/_colors.scss"],"names":[],"mappings":"AAEA,sCACI,kBAAA,CACA,UAAA,CAGJ,sCACI,kBAAA,CACA,YAAA,CAEA,4CACI,iBAAA,CAGJ,4EACI,gBAAA,CACA,iBAAA,CAGJ,kFACI,iBAAA,CAGJ,yDACI,8BAAA,CAGJ,6FACI,uBAAA,CAIR,sCACI,iBAAA,CAGJ,sCACI,wBAAA,CACA,iBAAA,CACA,aC/BS,CDgCT,cAAA,CACA,cAAA,CACA,eAAA,CACA,SAAA,CACA,oBAAA,CAGJ,sCACI,YAAA,CACA,6BAAA,CACA,SAAA,CAGJ,sCACI,kBAAA,CAGJ,sCACI,aClDS,CDmDT,cAAA,CACA,cAAA,CACA,eAAA,CACA,oBAAA,CAGJ,sCACI,cAAA","sourceRoot":""}]);
// Exports
export var green7 = `#409618`;
export var red7 = `#d4503b`;
export var red8 = `#c24632`;
export var inputsBlock = `loginPage-moduleHbEPEzQGOi_7XknPp0jh`;
export var loginInputWrap = `loginPage-moduleUOcpMCblfTM1jz7D5DW0`;
export var icon = `loginPage-moduleUIOI0k1qKlT87a7SWUUl`;
export var inputBlock = `loginPage-moduleW9QncnxDD_yPXBKxogLY`;
export var option = `loginPage-modulerMvPZDo_o8YPObQZyAgM`;
export var optionsBlock = `loginPage-moduleiQuHD7NuqQ_m7SszQqOv`;
export var loginBtnWrapper = `loginPage-moduleRyvfRRiizhFHpRerBdBK`;
export var singForFree = `loginPage-moduleRhC9RXQd9lSxVOsxnY1k`;
export var errorMessage = `loginPage-moduleBxUgkc_zDlX_p2Ywarzf`;
export default ___CSS_LOADER_EXPORT___;
