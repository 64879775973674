/* eslint-disable no-param-reassign */
import React from 'react';
import { Flex } from '@magnetic/flex';
import { Negative, Positive } from '@magnetic/icons';
import { Text } from '@magnetic/text';

import {
    PRODUCT_NAMES,
    REQUEST,
    STC_PRODUCTS,
    TRIAL_STATUS_VALUES,
    XDR_REGIONS,
} from './constants';

const createBlob = (data, mimeType) => new Blob([data], { type: mimeType });

export const downloadCsvFile = ({ file, orgId, title }) => {
    const anchor = document.createElement('a');
    const windowUrl = window.URL || window.webkitURL;

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const [time, timeZone] = today
        .toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        })
        .split(' ');
    const formattedTime = time.replaceAll(':', '_');

    const date = `${year}_${month}_${day}_${formattedTime}_${timeZone}`;

    const filename = `${title}${orgId || ''}_${date}.csv`;
    const blob = createBlob(file, 'text/csv');

    const url = windowUrl.createObjectURL(blob);
    anchor.href = url;
    anchor.download = filename;
    anchor.click();
    windowUrl.revokeObjectURL(url);
};

export const generateRequestBasedOnFilters = (
    whereQuery,
    pagination,
    optionalFields = true,
) => {
    const requestString = new URLSearchParams();

    // In some cases we can skip pagination
    // like when we generate additional object to clone with initial state object
    if (pagination) {
        requestString.append('page', pagination?.page || 1);
        requestString.append('limit', pagination?.limit || 25);

        if (
            typeof pagination.offset !== 'undefined' &&
            pagination.offset !== null
        ) {
            requestString.append('offset', pagination.offset);
        }
    }

    if (optionalFields) {
        requestString.append(
            'optionalFields',
            JSON.stringify([
                REQUEST.CUSTOM_FIELD_ORIGINCOUNTS,
                REQUEST.CUSTOM_FIELD_LASTLOGIN,
            ]),
        );
    }

    // get where key from the object
    if (whereQuery.where) {
        const statements = Object.keys(whereQuery.where);

        statements.forEach((statement) => {
            // TODO need to remove 'if' once filtering is ready
            if (statement !== 'expiryDate') {
                const whereValue = whereQuery.where[statement].value;
                if (whereValue) {
                    let mode = '';
                    // Generate where[option][mode] like key
                    if (whereQuery.where[statement].mode) {
                        mode = `[${whereQuery.where[statement].mode}]`;
                    }

                    // in case when value is array for IN statement
                    if (Array.isArray(whereValue) && whereValue.length > 0) {
                        whereValue.forEach((value) => {
                            requestString.append(
                                `where[${whereQuery.where[statement].option}]${mode}[]`,
                                value,
                            );
                        });
                    } else {
                        requestString.append(
                            `where[${whereQuery.where[statement].option}]${mode}`,
                            whereValue,
                        );
                    }
                }
            }
        });
    }

    // get order key from object
    if (whereQuery.order) {
        const statements = Object.keys(whereQuery.order);
        statements.forEach((statement) => {
            if (
                whereQuery.order[statement].option &&
                whereQuery.order[statement].mode
            ) {
                requestString.append(
                    `order[${whereQuery.order[statement].option}]`,
                    whereQuery.order[statement].mode,
                );
            }
        });
    }

    // get filters from object
    if (whereQuery.filters) {
        const statements = Object.keys(whereQuery.filters);
        statements.forEach((statement) => {
            const filters = {};
            let filterStatements = '';
            // createAt date range filter
            if (statement === 'createdAt') {
                filterStatements = Object.keys(whereQuery.filters[statements]);
                filterStatements.forEach((criteria) => {
                    if (whereQuery.filters[statement][criteria].value) {
                        if (!filters[statement]) {
                            filters[statement] = {};
                        }

                        filters[statement][criteria] =
                            whereQuery.filters[statement][criteria].value;
                    }
                });
            } else if (
                whereQuery.filters[statement] &&
                whereQuery.filters[statement].option &&
                whereQuery.filters[statement].value
            ) {
                filters[whereQuery.filters[statement].option] =
                    whereQuery.filters[statement].value;
            }

            // If filters have at least one not empty param
            if (Object.entries(filters).length !== 0) {
                requestString.append('filters', JSON.stringify(filters));
            }
        });
    }

    if (whereQuery?.objectOrder?.from?.value) {
        requestString.append('from', whereQuery.objectOrder.from.value);
        requestString.append('to', whereQuery.objectOrder.to.value);
    }

    if (whereQuery?.userId?.value) {
        requestString.append('userId', whereQuery.userId.value);
    }

    if (whereQuery?.productType) {
        requestString.append('productType', whereQuery.productType);
    }

    return requestString;
};

export const setObjectValueByPath = (obj, prop, val) => {
    const props = prop.split('.');
    const final = props.pop();

    let p;
    for (let i = 0; i < props.length; i += 1) {
        p = props[i];
        if (typeof obj[p] === 'undefined') {
            // If we're setting
            if (typeof val !== 'undefined') {
                // If we're not at the end of the props, keep adding new empty objects
                if (i !== props.length) {
                    obj[p] = {};
                }
            } else {
                return undefined;
            }
        }
        obj = obj[p];
    }

    if (typeof val !== 'undefined') {
        obj[final] = val;
        return obj[final];
    }

    return obj[final];
};

export const setObjectValueByPathBulk = (obj, newStates = {}) => {
    newStates.forEach((state) => {
        setObjectValueByPath(obj, state.filterPath, state.value);
    });
};

export const onFormatDate = (date) => {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    const hourOptions = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };
    if (!date) {
        return {};
    }
    const dateValue = new Date(date).toLocaleDateString('en-US', options);
    const timeValue = new Date(date).toLocaleString('en-US', hourOptions);

    return { dateValue, timeValue };
};

export const numericFormatDate = (date) => {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    if (!date) {
        return {};
    }
    return new Date(date).toLocaleDateString('en-US', options);
};

export const handleProduct = (responseValue) => {
    const productMap = {
        umbrella: PRODUCT_NAMES.umbrella,
        secureEndpoint: PRODUCT_NAMES.secureEndpoint,
        xdr: PRODUCT_NAMES.xdr,
        aiDefense: PRODUCT_NAMES.aiDefense,
        scc: PRODUCT_NAMES.scc,
    };

    return productMap[responseValue] || '';
};

export const trialErrorHandler = (creationResponse, status) => {
    const result = {
        customerInfo: creationResponse?.trials?.customer,
        informationSet: [],
    };

    if (creationResponse?.trials?.trials) {
        const productResponse = creationResponse?.trials?.trials;

        const products = Object.keys(productResponse);

        products.forEach((el) => {
            switch (productResponse[el].status) {
                case 'error':
                    result.informationSet.push({
                        message: productResponse[el].error,
                        type: 'error',
                        product: handleProduct(el),
                    });

                    break;

                case 'created':
                    result.informationSet.push({
                        message: `${handleProduct(el)} trial: ${
                            productResponse[el].error
                        }`,
                        type: 'warning',
                    });

                    break;

                default:
                    result.informationSet.push({
                        message: handleProduct(el),
                        type: 'positive',
                    });
            }
        });
    }

    if (creationResponse?.status === 'error') {
        if (typeof creationResponse?.error === 'string') {
            result.informationSet.push({
                message: creationResponse.error,
                type: 'error',
            });
        } else {
            const detailsErrors = Object.entries(
                creationResponse?.error?.details || {
                    unexpectedError: 'Unknown Error',
                },
            ).map(([key, value]) => `${key} ${value},`);

            detailsErrors.forEach((el) => {
                result.informationSet.push({
                    message: el,
                    type: 'error',
                });
            });
        }
    }

    if (status === 'rejected' && !Object.keys(creationResponse)?.length) {
        result.informationSet.push({
            message: 'network',
            type: 'error',
        });
    }

    result.isShowNotifications = result.informationSet.some(
        (element) => element.type === 'error',
    );

    return result;
};

export const productTypeHandler = (productType) => {
    const product = STC_PRODUCTS.find((el) => el.id === String(productType));
    return product ? product.name : '–';
};

export const handleParentType = (type, orgTypes) => {
    let typeName = '-';

    orgTypes.forEach((el) => {
        if (el.id === type) {
            typeName = el.name;
        }
    });

    return typeName;
};

export const errorHandler = (errorObj) => {
    let dynamicError;

    if (errorObj?.message) {
        dynamicError = errorObj?.message;
    }

    if (errorObj?.error && typeof errorObj.error !== 'object') {
        if (errorObj?.error === 'Method Not Allowed') {
            dynamicError =
                'Permission denied: You are not authorized to complete this action.';
        } else {
            dynamicError = errorObj?.error;
        }
    }

    const traverseErrors = (obj, parentKey = '') => {
        Object.keys(obj || {}).forEach((key) => {
            const fullKey = parentKey ? `${parentKey} ${key}` : key;
            const value = obj[key];

            if (typeof value === 'object' && value !== null) {
                if (value.code_text) {
                    dynamicError.push(`${fullKey} ${value.code_text}, `);
                } else {
                    traverseErrors(value, fullKey);
                }
            } else {
                dynamicError.push(`${fullKey} ${value}, `);
            }
        });
    };

    if (errorObj?.error && typeof errorObj.error === 'object') {
        dynamicError = [];

        if (errorObj?.error?.details) {
            Object.keys(errorObj?.error?.details || {}).forEach((el) => {
                dynamicError.push(`${el} ${errorObj?.error?.details[el]}`);
            });
        } else {
            traverseErrors(errorObj?.error);
        }
    }

    return dynamicError || 'Unknown error';
};

export const countDays = (startTime, endTime) => {
    const differenceInMilliseconds = Math.abs(endTime - startTime);

    const millisecondsInOneDay = 24 * 60 * 60 * 1000;

    const differenceInDays = Math.floor(
        differenceInMilliseconds / millisecondsInOneDay + 1,
    );

    return differenceInDays;
};

export const handleRegion = (id) => {
    let regionName;

    XDR_REGIONS.forEach((el) => {
        if (el.value === String(id)) {
            regionName = el.label;
        }
    });
    return regionName;
};

export const defineRegionPackageById = (id, valueType, packages = []) => {
    const packageInfo = packages.find((el) => +el.id === +id);

    if (!packageInfo) return '';

    return valueType === 'region' ? packageInfo.region : packageInfo.name;
};

export const regionByPackageIdHandler = (packages, packageId) => {
    const [neededPackage] = packages.filter((el) => el.id === packageId);

    return neededPackage;
};

export const thTenantHandler = (trial) => {
    const te = trial?.thousandEyes;

    if (te.status === 'active' && te.tenantId) {
        return (
            <Flex as="span" align="center" gap={4}>
                <Positive style={{ flexShrink: 0 }} />
                <Text as="span" weight="semi-bold" data-testid="tenantId">
                    {te.status ?? '–'}
                </Text>
            </Flex>
        );
    }

    if (te.comments) {
        const parsedData = JSON.parse(te.comments);

        return (
            <Flex as="span" align="center" gap={4}>
                <Negative style={{ flexShrink: 0 }} />
                <Text as="span" weight="semi-bold" data-testid="errorMessage">
                    [{parsedData.errorCode ?? '–'}]{' '}
                    {parsedData.errorMessage ?? '–'}
                </Text>
            </Flex>
        );
    }

    return <span>–</span>;
};

export const handleEditStartEndDateAbility = ({
    startsAt,
    createdAt,
    endsAt,
    isValidateExpire,
    isValidateStart,
    isForStartEditButton,
    isForCalendar,
    endDateLimit = 365,
    maxStartDateValue = 30,
    status,
    isClaimed,
    current = new Date(),
    isForEndEditButton,
}) => {
    // Checks for startDate validations
    if (isValidateStart) {
        //Disable calendar dates for start date
        // mandatory parameters: createdAt, maxStartDateValue, current
        if (isForCalendar) {
            const today = new Date();
            const maxStartDate = new Date(createdAt);
            maxStartDate.setDate(maxStartDate.getDate() + maxStartDateValue);
            maxStartDate.setHours(0, 0, 0, 0);

            const disableDate = current > maxStartDate || current < today;

            return disableDate;
        }
        //Check disable edit button for start date
        //Mandatory parameters: startsAt, isForEditButton, status
        if (isForStartEditButton) {
            const disableEdit =
                status !== TRIAL_STATUS_VALUES.PENDING ||
                Date.parse(startsAt) < Date.now();

            return disableEdit;
        }
    }
    // Checks for endDate validations
    if (isValidateExpire) {
        //Disable calendar dates for end date
        // mandatory parameters: startsAt, current, endDateLimit
        if (isForCalendar) {
            const minExpDate =
                Date.parse(startsAt) < Date.now()
                    ? Date.now()
                    : Date.parse(startsAt);

            const maxEndDate = new Date(startsAt);
            maxEndDate.setDate(maxEndDate.getDate() + endDateLimit);

            const disableDate = current < minExpDate || current > maxEndDate;

            return disableDate;
        }

        //Disable edit button for end date
        // mandatory parameters: isForEndEditButton, gracePeriod, isClaimed, endsAt, status
        if (isForEndEditButton) {
            let disableEdit = true;

            // Check if the status is either CONVERTED or DISABLED
            if (
                status === TRIAL_STATUS_VALUES.CONVERTED ||
                status === TRIAL_STATUS_VALUES.DISABLED
            ) {
                return disableEdit; // Return true to disable edit
            }

            // Check if the current date is before the end date
            if (Date.parse(endsAt) > Date.now()) {
                return false; // Return false to enable edit
            }

            // Set disableEdit based on whether the trial is claimed
            disableEdit = isClaimed;

            return disableEdit; // Return the final value of disableEdit
        }
    }
};

export const deepCopy = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => deepCopy(item));
    }

    const copy = {};
    for (const key in obj) {
        if (obj && Object.prototype.hasOwnProperty.call(obj, key)) {
            copy[key] = deepCopy(obj[key]);
        }
    }

    return copy;
};

export const capitalizeFirstLetter = (string) => {
    return string?.replace(/\b\w/g, (char) => char.toUpperCase());
};
