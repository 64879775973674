import React from 'react';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Box, LinearProgress } from '@mui/material';
import { Spinner } from '@magnetic/spinner';

import { columns, useRoles } from './config';

import * as g from '../../../../../../styles/globalStyles.module.scss';

const gridStyles = {
    '.MuiDataGrid-row': {
        cursor: 'pointer',
    },
    '.MuiDataGrid-footerContainer': {
        display: 'none',
    },
    '.MuiDataGrid-columnSeparator': {
        display: 'none',
    },
};

const UserRolesGrid = () => {
    const history = useHistory();

    const { data, loading } = useRoles();

    return (
        <div data-testid="userRolesGrid">
            {loading && (
                <div
                    className={g.contentPreloaderWithBlur}
                    data-testid="userRolesGridPreloader"
                >
                    <Spinner />
                </div>
            )}

            <Box>
                <DataGrid
                    sx={gridStyles}
                    onCellClick={(row) =>
                        history.replace(`user-roles/${row.id}`)
                    }
                    rows={data}
                    columns={columns}
                    rowHeight={50}
                    autoHeight
                    disableColumnMenu
                    disableSelectionOnClick
                    loading={loading}
                    components={{
                        LoadingOverlay: LinearProgress,
                    }}
                />
            </Box>
        </div>
    );
};

export default UserRolesGrid;
