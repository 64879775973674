// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entryWrapperFooter-moduleMndLzpovMpI8NHQiWYwA{align-items:center;background:hsla(0,0%,100%,.8);bottom:0;display:flex;justify-content:center;padding:12px;position:fixed;width:100%}.entryWrapperFooter-modulep5JAelOQSjCG8Jgn8ay1{font-size:12px;font-weight:300;margin-right:3px}.entryWrapperFooter-moduleNhiFLpJ9FUViOn5vfxLZ{color:#049fd9;cursor:pointer;font-size:14px;font-weight:400;text-decoration:none}.entryWrapperFooter-moduleyOUvn57pnir75G5a3dby{font-size:12px;font-weight:300;margin:0 3px}`, "",{"version":3,"sources":["webpack://./src/components/entryWrapper/components/entryWrapperFooter/entryWrapperFooter.module.scss","webpack://./src/styles/variables/_colors.scss"],"names":[],"mappings":"AAEA,+CACI,kBAAA,CACA,6BAAA,CACA,QAAA,CACA,YAAA,CACA,sBAAA,CACA,YAAA,CACA,cAAA,CACA,UAAA,CAGJ,+CACI,cAAA,CACA,eAAA,CACA,gBAAA,CAGJ,+CACI,aCVI,CDWJ,cAAA,CACA,cAAA,CACA,eAAA,CACA,oBAAA,CAGJ,+CACI,cAAA,CACA,eAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
export var green7 = `#409618`;
export var red7 = `#d4503b`;
export var red8 = `#c24632`;
export var loginFooter = `entryWrapperFooter-moduleMndLzpovMpI8NHQiWYwA`;
export var copyrightDescription = `entryWrapperFooter-modulep5JAelOQSjCG8Jgn8ay1`;
export var footerLink = `entryWrapperFooter-moduleNhiFLpJ9FUViOn5vfxLZ`;
export var barrier = `entryWrapperFooter-moduleyOUvn57pnir75G5a3dby`;
export default ___CSS_LOADER_EXPORT___;
