/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../../../common/constants';
import apiV1 from '../../../../services/axiosConfig/apiV1';

const initialState = {
    getAuditLogStatus: FETCH_STATUS.INIT,
    getAuditLogResponseData: {},
    auditLogCSVResponse: {},
    auditLogCSVStatus: FETCH_STATUS.INIT,
};

const auditLogFilterHandler = ({
    ip,
    method,
    email,
    orderBy,
    sortBy,
    from,
    to,
}) => {
    const filterParams = new URLSearchParams();

    // ip filter
    if (ip) {
        filterParams.append('where[ip]', ip);
    }

    // method filter
    if (method) {
        filterParams.append('where[action]', method);
    }

    // email filter
    if (email) {
        filterParams.append('where[email]', email);
    }

    // sorting
    if (orderBy && sortBy) {
        filterParams.append(`order[${sortBy}]`, orderBy);
    }

    // dates filtering
    if (from && to) {
        filterParams.append('where[from]', from);
        filterParams.append('where[to]', to);
    }

    return filterParams;
};

export const getAuditLog = createAsyncThunk(
    'auditLogPage/getAuditLog',
    async (
        { limit, offset, ip, method, email, orderBy, sortBy, from, to },
        { rejectWithValue },
    ) => {
        try {
            const url = `${process.env.REACT_APP_STC_API_HOST}/bfg/auditlog?limit=${limit}&offset=${offset}&`;

            const filterParams = auditLogFilterHandler({
                ip,
                method,
                email,
                orderBy,
                sortBy,
                from,
                to,
            });

            const response = await apiV1.sendRequest(
                `${url}${filterParams}`,
                'get',
            );

            return response.data.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getLogsCSV = createAsyncThunk(
    'auditLogPage/getLogsCSV',
    async (
        { limit, offset, ip, method, email, orderBy, sortBy, from, to },
        { rejectWithValue },
    ) => {
        try {
            const url = `${process.env.REACT_APP_STC_API_HOST}/bfg/auditlog/csv?limit=${limit}&offset=${offset}&`;
            const filterParams = auditLogFilterHandler({
                ip,
                method,
                email,
                orderBy,
                sortBy,
                from,
                to,
            });

            const response = await apiV1.sendRequest(
                `${url}${filterParams}`,
                'get',
            );

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

const auditLogSlice = createSlice({
    name: 'auditLogSlice',
    initialState,
    reducers: {
        resetCSVStatus(state) {
            return {
                ...state,
                auditLogCSVStatus: FETCH_STATUS.INIT,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAuditLog.pending, (state) => {
                state.getAuditLogStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(getAuditLog.fulfilled, (state, action) => {
                state.getAuditLogStatus = FETCH_STATUS.SUCCESS;
                state.getAuditLogResponseData = action?.payload;
            })
            .addCase(getAuditLog.rejected, (state, action) => {
                state.getAuditLogStatus = FETCH_STATUS.ERROR;
                state.getAuditLogResponseData = action?.payload;
            })
            .addCase(getLogsCSV.pending, (state) => {
                state.auditLogCSVStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(getLogsCSV.fulfilled, (state, action) => {
                state.auditLogCSVStatus = FETCH_STATUS.SUCCESS;
                state.auditLogCSVResponse = action?.payload;
            })
            .addCase(getLogsCSV.rejected, (state, action) => {
                state.auditLogCSVStatus = FETCH_STATUS.ERROR;
                state.auditLogCSVResponse = action?.payload;
            });
    },
});

export const { resetCSVStatus } = auditLogSlice.actions;

export default auditLogSlice.reducer;
