// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboardUserPage-modulehNnTmUtZYYbklqrbpG6o{width:100%}.onboardUserPage-modulep0N_rrhxHuofMWUTvSDe{color:#58585b;font-size:13px;font-weight:200;line-height:1.4;padding-top:8px}.onboardUserPage-modulep0N_rrhxHuofMWUTvSDe h3{font-weight:200}.onboardUserPage-modulep0N_rrhxHuofMWUTvSDe ul{list-style:disc;padding:8px 0 16px 16px}.onboardUserPage-moduleSjDrpIOC1E3qrHwXOTPQ{margin-top:5px}.onboardUserPage-modulef4EFfGIhSswcN7_aE9EU{align-items:center;display:flex;flex-direction:column;margin-top:15px}.onboardUserPage-modulef4EFfGIhSswcN7_aE9EU .onboardUserPage-moduleNgllBwtWO4_BxmUO0v2y{margin-bottom:10px}`, "",{"version":3,"sources":["webpack://./src/pages/onboardUserPage/onboardUserPage.module.scss","webpack://./src/styles/variables/_colors.scss"],"names":[],"mappings":"AAEA,4CACI,UAAA,CAGJ,4CACI,aCFc,CDGd,cAAA,CACA,eAAA,CACA,eAAA,CACA,eAAA,CAEA,+CACI,eAAA,CAGJ,+CACI,eAAA,CACA,uBAAA,CAIR,4CACI,cAAA,CAGJ,4CACI,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CAEA,wFACI,kBAAA","sourceRoot":""}]);
// Exports
export var green7 = `#409618`;
export var red7 = `#d4503b`;
export var red8 = `#c24632`;
export var form = `onboardUserPage-modulehNnTmUtZYYbklqrbpG6o`;
export var onboardingPasswordInfo = `onboardUserPage-modulep0N_rrhxHuofMWUTvSDe`;
export var errorMessage = `onboardUserPage-moduleSjDrpIOC1E3qrHwXOTPQ`;
export var actionButtonsContainer = `onboardUserPage-modulef4EFfGIhSswcN7_aE9EU`;
export var buttonsSpaceBetween = `onboardUserPage-moduleNgllBwtWO4_BxmUO0v2y`;
export default ___CSS_LOADER_EXPORT___;
