import React from 'react';
import { useHistory } from 'react-router-dom';
import { Empty } from '@magnetic/icons';
import { Button } from '@magnetic/button';
import { Flex } from '@magnetic/flex';
import { Heading } from '@magnetic/heading';
import { Text } from '@magnetic/text';

import * as s from './noPageFound.module.scss';

const NoPageFound = ({ location, isUpperRoute }) => {
    const history = useHistory();

    return (
        <Flex
            direction="vertical"
            align="center"
            justify="center"
            data-testid="noPageFound"
            style={{ height: '100%' }}
        >
            <Empty.Info />

            <Flex direction="vertical" align="center" gap={8}>
                <Heading>Error: 404 Page not found</Heading>

                <Text size="p2">
                    The page you are looking for might have been removed or is
                    temporary unavailable
                </Text>
            </Flex>

            {isUpperRoute && (
                <div className={s.btn}>
                    <Button
                        data-testid="goHomeBtn"
                        kind="primary"
                        onClick={() => {
                            history.push(location);
                        }}
                    >
                        Go Home
                    </Button>
                </div>
            )}
        </Flex>
    );
};

export default NoPageFound;
