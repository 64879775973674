import React from 'react';

import EntryWrapperFooter from './components/entryWrapperFooter/EntryWrapperFooter';

import * as s from './entryWrapper.module.scss';

const EntryWrapper = ({ children }) => {
    return (
        <div className={s.entryWrapper} data-testid="entryWrapper">
            <div className={s.formContainer}>
                <div className={s.form}>{children}</div>
            </div>

            <EntryWrapperFooter />
        </div>
    );
};

export default EntryWrapper;
