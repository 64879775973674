import { setupListeners } from '@reduxjs/toolkit/query';
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

import rootReducer from './rootReducer';
import { auditLogApi } from '../services/auditLogApi';

const middlewares = [thunk, auditLogApi.middleware];

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
    enhancers: (getDefaultEnhancers) =>
        getDefaultEnhancers({
            autoBatch: false,
        }).concat(),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;
