/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../../../../../common/constants';
import apiV1 from '../../../../../../services/axiosConfig/apiV1';

const initialState = {
    // add user state
    addUserToOrgStatus: FETCH_STATUS.INIT,
    addUserToOrgResult: {},

    // remove users state
    removeUsersStatus: FETCH_STATUS.INIT,
    removeUsersResult: {},

    // create user state
    createSTCUserStatus: FETCH_STATUS.INIT,
    createSTCUserResult: {},

    // create user state
    getManagersStatus: FETCH_STATUS.INIT,
    getManagersResult: {},
};

export const createSTCUser = createAsyncThunk(
    'users/createSTCUser',
    async ({ body, orgId }, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(
                `/bfg/organizations/${orgId}/users/create`,
                'post',
                body,
            );

            return response;
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const removeUsersFromOrg = createAsyncThunk(
    'users/removeUsersFromOrg',
    async ({ orgId, users }, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(
                `/bfg/organizations/${orgId}/users/remove`,
                'put',
                { users },
            );
            return response;
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const addUserToOrg = createAsyncThunk(
    'users/addUserToOrg',
    async ({ orgId, payload }, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(
                `/bfg/organizations/${orgId}/users/add`,
                'post',
                payload,
            );
            return response;
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getManagers = createAsyncThunk(
    'users/getManagers',
    async ({ orgId }, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(
                `/bfg/organizations/${orgId}/users/managers?order%5BfirstName%5D=asc`,
            );

            return response?.data?.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

const usersSlice = createSlice({
    name: 'usersSlice',
    initialState,
    reducers: {
        clearStatusAndResult(state) {
            return {
                ...state,
                addUserToOrgStatus: FETCH_STATUS.INIT,
                addUserToOrgResult: {},
            };
        },
        clearUsersForDeletion(state) {
            return {
                ...state,
                removeUsersStatus: FETCH_STATUS.INIT,
                removeUsersResult: {},
            };
        },
        clearRemoveUsersStatus(state) {
            return {
                ...state,
                removeUsersStatus: FETCH_STATUS.INIT,
            };
        },
        clearCreateSTCUserData(state) {
            return {
                ...state,
                createSTCUserStatus: FETCH_STATUS.INIT,
                createSTCUserResult: {},
            };
        },
    },
    extraReducers: (builder) => {
        builder
            // add user to org
            .addCase(addUserToOrg.pending, (state) => {
                state.addUserToOrgStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(addUserToOrg.fulfilled, (state, action) => {
                state.addUserToOrgStatus = FETCH_STATUS.SUCCESS;
                state.addUserToOrgResult = action?.payload;
            })
            .addCase(addUserToOrg.rejected, (state, action) => {
                state.addUserToOrgStatus = FETCH_STATUS.ERROR;
                state.addUserToOrgResult = action?.payload;
            })

            // remove users from org
            .addCase(removeUsersFromOrg.pending, (state) => {
                state.removeUsersStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(removeUsersFromOrg.fulfilled, (state, action) => {
                state.removeUsersStatus = FETCH_STATUS.SUCCESS;
                state.removeUsersResult = action.payload;
            })
            .addCase(removeUsersFromOrg.rejected, (state, action) => {
                state.removeUsersStatus = FETCH_STATUS.ERROR;
                state.removeUsersResult = action.payload;
            })

            // create stc user and add it to a org
            .addCase(createSTCUser.pending, (state) => {
                state.createSTCUserStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(createSTCUser.fulfilled, (state, action) => {
                state.createSTCUserStatus = FETCH_STATUS.SUCCESS;
                state.createSTCUserResult = action.payload;
            })
            .addCase(createSTCUser.rejected, (state, action) => {
                state.createSTCUserStatus = FETCH_STATUS.ERROR;
                state.createSTCUserResult = action.payload;
            })

            // get managers
            .addCase(getManagers.pending, (state) => {
                state.getManagersStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(getManagers.fulfilled, (state, action) => {
                state.getManagersStatus = FETCH_STATUS.SUCCESS;
                state.getManagersResult = action.payload;
            })
            .addCase(getManagers.rejected, (state, action) => {
                state.getManagersStatus = FETCH_STATUS.ERROR;
                state.getManagersResult = action.payload;
            });
    },
});

export const {
    clearStatusAndResult,
    clearUsersForDeletion,
    clearRemoveUsersStatus,
    clearCreateSTCUserData,
} = usersSlice.actions;

export default usersSlice.reducer;
