import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@magnetic/spinner';
import { Flex } from '@magnetic/flex';
import { Notification } from '@magnetic/notification';
import { EnvelopeSimple, User } from '@magnetic/icons';
import { Button } from '@magnetic/button';
import { Input } from '@magnetic/input';

import EntryWrapper from '../../components/entryWrapper/EntryWrapper';
import logo from '../../images/logo/loginLogo.svg';
import useHexCheck from '../../common/checkHex';
import { useDocumentTitle } from '../../utils/useDocumentTitle';
import { onboardInternalCiscoUser } from '../dashboardPage/components/onboarding/onboardSlice';
import { FETCH_STATUS } from '../../common/constants';
import { updateAlertParams } from '../../slices/alert/alertSlice';

import * as g from '../../styles/globalStyles.module.scss';
import * as s from './onboardUserPage.module.scss';

const CreateInternalCiscoUserPage = () => {
    useDocumentTitle('STAC - Onboarding');

    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();

    // check the hex validity hook
    useHexCheck(params);

    const { onboardInternalUserStatus, onboardResult } = useSelector(
        (state) => state.onboard,
    );

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    useEffect(() => {
        if (onboardInternalUserStatus === FETCH_STATUS.SUCCESS) {
            history.replace('/login');
        }

        if (onboardInternalUserStatus === FETCH_STATUS.ERROR) {
            dispatch(
                updateAlertParams({
                    severity: 'error',
                    message: onboardResult.error,
                }),
            );
        }
    }, [onboardInternalUserStatus, onboardResult]);

    const handleCreateSTCUser = (formData) => {
        const formDataWithUrlToken = {
            ...formData,
            token: params.hex,
        };
        dispatch(onboardInternalCiscoUser(formDataWithUrlToken));
    };

    return (
        <EntryWrapper>
            <img src={logo} alt="logo" />

            <h2 data-testid="formTitle">STC Management Console - Onboarding</h2>

            <form className={s.form}>
                <Flex direction="column" gap={16}>
                    {onboardInternalUserStatus === FETCH_STATUS.FETCHING && (
                        <div
                            data-testid="onboardUserLoader"
                            className={g.contentPreloaderWithBlur}
                        >
                            <Spinner />
                        </div>
                    )}

                    {/* First name */}
                    <Flex direction="vertical" gap={8}>
                        <Input
                            label="First Name"
                            invalid={Boolean(errors?.firstName)}
                            data-testid="onboardFirstNameInput"
                            {...register('firstName', { required: true })}
                            prefix={<User weight="bold" size={16} />}
                        />

                        {errors.firstName && (
                            <Notification status="negative">
                                First name is required!
                            </Notification>
                        )}
                    </Flex>

                    {/* Last name */}
                    <Flex direction="vertical" gap={8}>
                        <Input
                            label="Last Name"
                            invalid={Boolean(errors?.lastName)}
                            data-testid="onboardLastNameInput"
                            {...register('lastName', { required: true })}
                            prefix={<User weight="bold" size={16} />}
                        />

                        {errors.lastName && (
                            <Notification status="negative">
                                Last name is required!
                            </Notification>
                        )}
                    </Flex>

                    {/* Email */}
                    <Flex direction="vertical" gap={8}>
                        <Input
                            label="Email"
                            invalid={Boolean(errors?.email?.type)}
                            data-testid="onboardEmailInput"
                            {...register('email', {
                                required: true,
                                validate: (email) => validator.isEmail(email),
                            })}
                            prefix={<EnvelopeSimple weight="bold" size={16} />}
                        />

                        {errors?.email?.type === 'required' && (
                            <Notification status="negative">
                                Email is required!
                            </Notification>
                        )}

                        {errors?.email?.type === 'validate' && (
                            <Notification status="negative">
                                Email is not valid!
                            </Notification>
                        )}
                    </Flex>

                    <div className={s.actionButtonsContainer}>
                        <div
                            className={s.buttonsSpaceBetween}
                            data-testid="onboardNonSTCUserBtn"
                        >
                            <Button onClick={handleSubmit(handleCreateSTCUser)}>
                                Create
                            </Button>
                        </div>

                        <Button
                            kind="tertiary"
                            onClick={() => {
                                history.push('/login');
                            }}
                            data-testid="cancelOnboardNonSTCUserBtn"
                        >
                            Cancel
                        </Button>
                    </div>
                </Flex>
            </form>
        </EntryWrapper>
    );
};

export default CreateInternalCiscoUserPage;
