export const FETCH_STATUS = {
    INIT: 'INITIAL',
    FETCHING: 'FETCHING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const TRIAL_STATUS_VALUES = {
    ANY: 'any',
    ACTIVE_PENDING: 'activePending',
    ACTIVE: 'active',
    PENDING: 'pending',
    EXPIRED: 'expired',
    CONVERTED: 'converted',
    DISABLED: 'disabled',
    DEFAULT_SELECTED: '',
};

export const SORT_MODE = {
    ASC: 'asc',
    DESC: 'desc',
};

export const REQUEST = {
    CUSTOM_FIELD_ORIGINCOUNTS: 'originCounts',
    CUSTOM_FIELD_LASTLOGIN: 'lastLogin',
    CUSTOM_FIELD_ORIGINCOUNTSCSV: 'originCountsCsv',
    STATUS: {
        ACTIVE: 'active',
        HOLD: 'hold',
    },
};

export const DEBOUNCE_INPUT_TIME = 400;

export const REQUIRED_MESSAGE = 'Required';

export const IS_PASSWORD_REQUIRED = false;

export const PRODUCT_NAMES = {
    umbrella: 'Umbrella',
    secureEndpoint: 'Secure Endpoint',
    etd: 'ETD',
    xdr: 'XDR',
    secureAccess: 'Secure Access',
    aiDefense: 'AI Defense',
    scc: 'SCC Private Preview',
};

export const CUSTOMER_PRODUCTS = {
    umbrella: 'umbrella',
    secureEndpoint: 'secureEndpoint',
    etd: 'etd',
    xdr: 'xdr',
    aiDefense: 'aiDefense',
    scc: 'scc',
    secureAccess: 'secureAccess',
};

export const XDR_REGIONS = [
    {
        value: '13',
        sku: 'XDR-ADV',
        label: 'North America',
    },
    {
        value: '14',
        sku: 'XDR-ADV-EU',
        label: 'Europe',
    },
    {
        value: '15',
        sku: 'XDR-ADV-AP',
        label: 'APJC',
    },
];

export const STC_PRODUCTS = [
    {
        id: '1',
        name: 'Umbrella',
    },
    {
        id: '2',
        name: 'Secure Endpoint',
    },
    {
        id: '3',
        name: 'ETD',
    },
    {
        id: '4',
        name: 'XDR',
    },
    {
        id: '5',
        name: 'AI Defense',
    },
    {
        id: '6',
        name: 'SCC Private Preview',
    },
    {
        id: '7',
        name: 'Secure Access',
    },
];
