// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdnjs.cloudflare.com/ajax/libs/react-datepicker/2.8.0/react-datepicker.min.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;vertical-align:baseline;box-sizing:border-box}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{font-family:CiscoSans,"Helvetica Neue",Helvetica,Arial,sans-serif;line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:"";content:none}table{border-collapse:collapse;border-spacing:0}:root{--control-bg-weak-default: #fff;--effects-shadow-raised: 0 4px 22px rgba(0, 0, 0, 0.12157)}.react-datepicker{font-family:CiscoSans,sans-serif !important}`, "",{"version":3,"sources":["webpack://./src/styles/_reset.scss","webpack://./src/styles/missingMagneticVariables.scss","webpack://./src/styles/index.scss"],"names":[],"mappings":"AAAA,2ZAiFI,QAAA,CACA,SAAA,CACA,QAAA,CACA,cAAA,CACA,uBAAA,CACA,qBAAA,CAGJ,8EAWI,aAAA,CAEJ,KACI,iEAAA,CACA,aAAA,CAEJ,MAEI,eAAA,CAEJ,aAEI,WAAA,CAEJ,oDAII,UAAA,CACA,YAAA,CAEJ,MACI,wBAAA,CACA,gBAAA,CC3HJ,MACI,+BAAA,CACA,0DAAA,CCIA,kBACI,2CAAA","sourceRoot":""}]);
// Exports
export var green7 = `#409618`;
export var red7 = `#d4503b`;
export var red8 = `#c24632`;
export default ___CSS_LOADER_EXPORT___;
