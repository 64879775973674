import axios from 'axios';

class ApiV1 {
    accessToken = null;

    instance = null;

    constructor() {
        this.options = {
            baseURL: process.env.REACT_APP_STC_API_HOST,
            withCredentials: true,
        };

        this.tokenUrl = 'bfg/refresh/token';
        this.apiV1AccessToken = null;

        this.initHeaders();
    }

    initInstance() {
        this.instance = axios.create(this.options);
    }

    setAccessToken(jwtToken) {
        this.apiV1AccessToken = jwtToken;
        this.initHeaders();
    }

    async refreshToken() {
        try {
            const response = await this.instance.post(this.tokenUrl);

            this.apiV1AccessToken = response.data.data.accessToken;
            return response;
        } catch (error) {
            return error;
        }
    }

    async sendRequest(
        url,
        method = 'get',
        data = undefined,
        extraOptions = {},
    ) {
        await this.initHeaders(extraOptions);

        if (extraOptions.jsonHttpStatusOverride) {
            if (url.indexOf('?') === -1) {
                // eslint-disable-next-line no-param-reassign
                url += '?';
            } else {
                // eslint-disable-next-line no-param-reassign
                url += '&';
            }
            // eslint-disable-next-line no-param-reassign
            url += 'outputFormat=jsonHttpStatusOverride';
        }

        try {
            return await this.instance[method.toLowerCase()](url, data);
        } catch (error) {
            if (error?.response?.status === 401) {
                await this.refreshToken();
                await this.initHeaders();

                return this.instance[method](url, data).catch((err) => {
                    throw err.response.data;
                });
            }

            throw error?.response?.data;
        }
    }

    initHeaders(extraOptions) {
        return new Promise((resolve) => {
            if (this.apiV1AccessToken && !extraOptions?.withoutBearer) {
                this.options = {
                    ...this.options,
                    headers: {
                        Authorization: `Bearer ${this.apiV1AccessToken}`,
                    },
                };
            }

            if (extraOptions?.disableWithCreds) {
                this.options = {
                    ...this.options,
                    withCredentials: false,
                };
            } else {
                this.options = {
                    ...this.options,
                    withCredentials: true,
                };
            }
            this.initInstance();
            resolve();
        });
    }
}
const apiV1 = new ApiV1();
export default apiV1;
