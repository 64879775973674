// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.idleModal-moduleS53jV532Y7eq8xXke5J8{align-items:center;background-color:#fff;display:flex;flex-direction:column;left:50%;margin:0 auto;padding:48px 80px;position:absolute;top:50%;transform:translate(-50%, -50%);width:500px}.idleModal-moduleJN_Ju3pI4J1NYQHAJ8MH{position:fixed;top:0;width:100%;height:100%;background:rgba(0,0,0,.7);z-index:1000}.idleModal-modulemc1KA_XRVE8uWqdozXEB{margin:10px 0 12px;width:100%;text-align:center;font-size:22px;font-weight:400;color:#58585b}.idleModal-moduleOnj1CqoUnxpdO9wfN7tz{text-align:center;line-height:1.5;font-size:15px;color:#58585b;font-weight:400;margin-bottom:12px}`, "",{"version":3,"sources":["webpack://./src/components/idleModal/idleModal.module.scss","webpack://./src/styles/variables/_colors.scss"],"names":[],"mappings":"AAEA,sCACI,kBAAA,CACA,qBCJI,CDKJ,YAAA,CACA,qBAAA,CACA,QAAA,CACA,aAAA,CACA,iBAAA,CACA,iBAAA,CACA,OAAA,CACA,+BAAA,CACA,WAAA,CAGJ,sCACI,cAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,yBAAA,CACA,YAAA,CAGJ,sCACI,kBAAA,CACA,UAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,aC1Bc,CD6BlB,sCACI,iBAAA,CACA,eAAA,CACA,cAAA,CACA,aCjCc,CDkCd,eAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
export var green7 = `#409618`;
export var red7 = `#d4503b`;
export var red8 = `#c24632`;
export var contentWrapper = `idleModal-moduleS53jV532Y7eq8xXke5J8`;
export var modalWrapper = `idleModal-moduleJN_Ju3pI4J1NYQHAJ8MH`;
export var title = `idleModal-modulemc1KA_XRVE8uWqdozXEB`;
export var description = `idleModal-moduleOnj1CqoUnxpdO9wfN7tz`;
export default ___CSS_LOADER_EXPORT___;
