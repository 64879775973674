/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS, SORT_MODE } from '../../../../../../common/constants';
import {
    setObjectValueByPath,
    setObjectValueByPathBulk,
} from '../../../../../../common/utils';
import apiV1 from '../../../../../../services/axiosConfig/apiV1';

const initialState = {
    status: FETCH_STATUS.INIT,
    statusForCSV: FETCH_STATUS.INIT,
    logsCSV: {},
    filter: {
        where: {
            ip: { option: 'ip', value: '' },
            userId: { option: 'userId', value: null },
            from: {
                option: 'from',
                value: new Date(
                    new Date().setHours(new Date().getHours() - 24),
                ).toISOString(),
            },
            to: { option: 'to', value: '' },
        },
        order: {
            sortBy: {
                option: 'timestamp',
                mode: SORT_MODE.DESC,
            },
        },
    },
};

export const getLogsCSV = createAsyncThunk(
    'parentAuditLogSlice/getLogsCSV',
    async ({ orgId }, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(
                `bfg/auditlog/stc/csv/${orgId}`,
            );

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

const parentAuditLogSlice = createSlice({
    name: 'parentAuditLogSlice',
    initialState,
    reducers: {
        updateFilter(state, action) {
            if (!action.payload.filterPaths) {
                const { filterPath, value } = action.payload;
                setObjectValueByPath(state, filterPath, value);
            } else {
                setObjectValueByPathBulk(state, action.payload.filterPaths);
            }
        },
        resetStatusForCSV(state) {
            return {
                ...state,
                statusForCSV: FETCH_STATUS.INIT,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLogsCSV.pending, (state) => {
                state.statusForCSV = FETCH_STATUS.FETCHING;
            })
            .addCase(getLogsCSV.fulfilled, (state, action) => {
                state.statusForCSV = FETCH_STATUS.SUCCESS;
                state.logsCSV = action?.payload;
            })
            .addCase(getLogsCSV.rejected, (state) => {
                state.statusForCSV = FETCH_STATUS.ERROR;
            });
    },
});

export const { updateFilter, resetStatusForCSV } = parentAuditLogSlice.actions;

export default parentAuditLogSlice.reducer;
