// OKTA code generation and redirection functions
export const generateCodeVerifier = (str) => {
    return str
        .toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
};

async function generateCodeChallenge(verifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(verifier);
    const hash = await crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode(...new Uint8Array(hash)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}

export const redirectToOkta = async () => {
    localStorage.removeItem('codeVerifier');
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);
    const codeVerifier = btoa(String.fromCharCode.apply(null, array))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    localStorage.setItem('codeVerifier', codeVerifier);
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    const redirectOktaUrl = `${process.env.REACT_APP_BFG_OKTA_URL}/oauth2/default/v1/authorize?client_id=${process.env.REACT_APP_BFG_OKTA_CLIENT_ID}&response_type=code&response_mode=query&scope=${process.env.REACT_APP_OKTA_SCOPES}&redirect_uri=${process.env.REACT_APP_BFG_OKTA_REDIRECT_URI}&state=auth&code_challenge_method=S256&code_challenge=${codeChallenge}`;
    window.location.replace(`${redirectOktaUrl}`);
};
