import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@magnetic/spinner';

import { bfgOktaLogin, setTokenInfo } from '../loginPage/loginPageSlice';
import { FETCH_STATUS } from '../../common/constants';
import { getOktaIdToken } from './oktaSlice';
import apiV1 from '../../services/axiosConfig/apiV1';
import logo from '../../images/logo/loginLogo.svg';
import EntryWrapper from '../../components/entryWrapper/EntryWrapper';

const OktaPage = () => {
    const dispatch = useDispatch();

    const history = useHistory();

    const { getOktaTokenStatus, getOktaTokenResult } = useSelector(
        (state) => state.okta,
    );

    const { bfgOktaLoginStatus, location, bfgOktaLoginResult } = useSelector(
        (state) => state.login,
    );

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        // generate okta token
        dispatch(
            getOktaIdToken({
                payload: {
                    grant_type: 'authorization_code',
                    client_id: process.env.REACT_APP_BFG_OKTA_CLIENT_ID,
                    redirect_uri: process.env.REACT_APP_BFG_OKTA_REDIRECT_URI,
                    code: queryParams.get('code'),
                    code_verifier: localStorage.getItem('codeVerifier'),
                },
            }),
        );
    }, []);

    useEffect(() => {
        if (getOktaTokenStatus === FETCH_STATUS.SUCCESS) {
            dispatch(
                // login with the sso token
                bfgOktaLogin({
                    payload: { token: getOktaTokenResult?.data?.access_token },
                }),
            );
        } else if (getOktaTokenStatus === FETCH_STATUS.ERROR) {
            localStorage.setItem('isLoginError', true);
            history.push('/login');
        }
    }, [getOktaTokenStatus]);

    useEffect(() => {
        if (bfgOktaLoginStatus === FETCH_STATUS.SUCCESS) {
            apiV1.setAccessToken(bfgOktaLoginResult?.data?.data?.accessToken);
            // if bfg login is successful redirect to dashboard/accounts
            dispatch(setTokenInfo(bfgOktaLoginResult?.data?.data?.accessToken));
            history.replace(location);
        } else if (bfgOktaLoginStatus === FETCH_STATUS.ERROR) {
            localStorage.setItem(
                'isLoginError',
                bfgOktaLoginResult?.error ? bfgOktaLoginResult?.error : true,
            );
            history.push('/login');
        }
    }, [bfgOktaLoginStatus]);

    return (
        <EntryWrapper>
            <img src={logo} alt="logo" />

            <Spinner />
        </EntryWrapper>
    );
};

export default OktaPage;
