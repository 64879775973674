// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-moduleYyFm91B4bUC7kFr5uZdX{align-items:center;background-color:#2b5592;display:flex;height:60px;justify-content:space-between;position:fixed;width:100%;z-index:1}.navbar-modulegnalBFuyDVUrHqVnK8Mg{align-items:center;display:flex;flex-direction:row}.navbar-modulehLnJ3SbJosdpTOewQSSP{color:#fff;font-size:30px;padding-left:20px}.navbar-moduleW4jS_WHHZnGzIKJLESuk{color:#fff;padding:0 40px}`, "",{"version":3,"sources":["webpack://./src/pages/dashboardPage/components/navbar/navbar.module.scss","webpack://./src/styles/variables/_colors.scss"],"names":[],"mappings":"AAEA,mCACI,kBAAA,CACA,wBCaG,CDZH,YAAA,CACA,WAAA,CACA,6BAAA,CACA,cAAA,CACA,UAAA,CACA,SAAA,CAGJ,mCACI,kBAAA,CACA,YAAA,CACA,kBAAA,CAGJ,mCACI,UCpBI,CDqBJ,cAAA,CACA,iBAAA,CAGJ,mCACI,UC1BI,CD2BJ,cAAA","sourceRoot":""}]);
// Exports
export var green7 = `#409618`;
export var red7 = `#d4503b`;
export var red8 = `#c24632`;
export var navContainer = `navbar-moduleYyFm91B4bUC7kFr5uZdX`;
export var navigationActionsContainer = `navbar-modulegnalBFuyDVUrHqVnK8Mg`;
export var navLogo = `navbar-modulehLnJ3SbJosdpTOewQSSP`;
export var navEmail = `navbar-moduleW4jS_WHHZnGzIKJLESuk`;
export default ___CSS_LOADER_EXPORT___;
