import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@magnetic/button';
import { Spinner } from '@magnetic/spinner';
import { Notification } from '@magnetic/notification';

import { useDocumentTitle } from '../../utils/useDocumentTitle';
import logo from '../../images/logo/loginLogo.svg';
import EntryWrapper from '../../components/entryWrapper/EntryWrapper';
import { redirectToOkta } from '../../utils/okta';
import { resetRefreshToken } from './loginPageSlice';

import * as g from '../../styles/globalStyles.module.scss';
import * as s from './loginPage.module.scss';

const LoginPage = () => {
    useDocumentTitle('STAC - Login');

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isInactive, setIsInactive] = useState(false);
    const [isLoginFailed, setIsLoginFailed] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState('');

    const isSignOutDueInactive = localStorage.getItem('isSingOutDueInactive');
    const isLoginError = localStorage.getItem('isLoginError');

    useEffect(() => {
        if (isSignOutDueInactive) {
            setIsInactive(true);
            localStorage.removeItem('isSingOutDueInactive');
        }

        if (isLoginError) {
            setIsLoginFailed(true);
            if (isLoginError === 'No access') {
                setLoginErrorMessage("You don't have access to this console");
            }
            localStorage.removeItem('isLoginError');
        }
    }, []);

    return (
        <EntryWrapper>
            {isLoading && (
                <div
                    data-testid="getLogoPreloader"
                    className={g.contentPreloaderWithBlur}
                >
                    <Spinner />
                </div>
            )}

            <img src={logo} alt="logo" />

            <h2 data-testid="formTitle">
                Cisco Secure Trials Management Console
            </h2>

            {(isInactive || isLoginFailed) && (
                <div
                    data-testid="loggedOutDueInactivity"
                    style={{ margin: '8px 0', width: '100%' }}
                >
                    <Notification
                        status={isLoginFailed ? 'negative' : 'warning'}
                    >
                        {isLoginFailed
                            ? `Fail to log in user. ${loginErrorMessage}`
                            : "Your previous session timed out. For security reasons, we've signed you out just to be safe."}
                    </Notification>
                </div>
            )}

            <div className={s.loginBtnWrapper}>
                <Button
                    data-testid="loginBtn"
                    name="loginBtn"
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            redirectToOkta();
                        }
                    }}
                    onClick={() => {
                        setIsLoading(true);
                        dispatch(resetRefreshToken());
                        redirectToOkta();
                    }}
                >
                    Log In
                </Button>
            </div>
        </EntryWrapper>
    );
};

export default LoginPage;
